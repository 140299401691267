import React from 'react';
import Link from 'gatsby-link';
import { Grid, Row, Col } from 'react-flexbox-grid';
import CTA from '../../components/button';

function Project(props) {
  return (
    <div className="page-container">
      <Row middle="xs">
        <Col xs={12}>
          <h1>Project Page</h1>
          <p>Welcome to a project page.</p>
          <Link to="/">Go back to the homepage</Link>
        </Col>
      </Row>
    </div>
  );
}

export default Project;
